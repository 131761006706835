import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import '/public/scss/app.scss'

const app = createApp(App)
app.config.productionTip = false

app.use(Antd)
app.use(VueAxios,axios)
app.use(store)
app.use(router)
app.mount('#app')
