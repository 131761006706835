<template>
  <div class="col-12 status--wrapper">
    <Status />
  </div>
</template>

<script>
import Status from '@/views/Status'

export default {
  name: 'App',
  components: {
    Status
  }
}
</script>

<style lang="scss">

</style>
