<template>
  <div class="col-12 status--page-wrapper">
    <header class="col-12">
      <div class="col-12 logo--wrapper">
        <div class="container-custom">
          <div class="logo--wrap">
            <img src="../../public/images/logo.png" />
          </div>
        </div>
      </div>
      <div class="col-12 nav--wrapper">
        <div class="container-custom">
          <ul class="nav--wrap">
            <li>
              <a href="#status">Operation</a>
            </li>
            <li>
              <a href="#timeline">Uprages</a>
            </li>
          </ul>
        </div>
      </div>
    </header>

    <div class="col-12 header--wrapper">
      <div class="container-custom">
        <div class="header--wrap">
          <h4>System Status</h4>
        </div>
      </div>
    </div>


    <div class="sticky--wrapper">
      <div class="left">
        <div class="logo--wrap">
          <img src="../../public/images/logo.png" />
        </div>
      </div>
      <div class="right">
        <div class="sticky--item" v-for="(status,key) in services[0]" :key="key">

          <div class="name">{{status.name}}</div>
          <div class="status">

            <i class="power-off" v-if="!status.success"></i>
            <i class="power-on" v-if="status.success"></i>
            <!--<p :class="status.success ? 'success':'danger'" v-html="status.success ? 'nline' : 'ffline'"></p>-->

          </div>

        </div>
      </div>

    </div>
    <div class="col-12 status--wrapper" id="status">
      <div class="container-custom">
        <div class="col-12 status--wrap">
          <div class="col-12 status--header">
            <h4>All System Operational</h4>
          </div>
            <a-collapse class="status--item-wrapper" v-model:activeKey="activeKey">
              <a-collapse-panel class="status--item" :header="`${status.name}`" :class="status.success ? 'online' : 'offline'" v-for="(status,key) in services[0]" :key="key">
                <template #extra>
                  <i class="power-off" v-if="!status.success"></i>
                  <i class="power-on" v-if="status.success"></i>

                  <p class="success" v-html="status.success ? 'nline' : 'offline'"></p>

                </template>

                <ul v-if="status.name != 'Worker'" class="desc--list">
                  <li  v-for="(value,key) in status.data" >
                    <h6>{{ key }}</h6> <p>{{  value }}  </p>
                  </li>

                </ul>


                <div class="col-12 not--data" v-if="!status.data">
                  <p>There is no content to display.</p>
                </div>

                <div class="col-12 not--data" v-if="!status.success">
                  <p>System information cannot be accessed, please contact your system administrator.</p>
                </div>
                <div v-if="status.name == 'Worker'" v-for="(value,key) in status.data"  class="worker--wrap">

                  <ul class="desc--list">
                    <li>
                      <h6>{{ value.name }}</h6> <p>{{  value.success }}  </p>
                    </li>

                  </ul>
                  <div  class="col-12 queue--wrap" v-if="value?.data?.queues.length > 0">
                    <div class="col-12 queue header">
                      <p>Queue Name</p>
                      <p>Number of pending jobs</p>
                    </div>
                    <div class="col-12 queue" v-for="queue in value?.data?.queues">

                        <span>{{queue.name}}</span> <span>{{queue.waiting_job}}</span>

                    </div>
                  </div>
                </div>
              </a-collapse-panel>

            </a-collapse>


        </div>
      </div>
    </div>
    <div class="col-12 timeline--wrapper" id="timeline">
      <div class="container-custom">
        <div class="col-12 timeline--wrap">
          <div class="col-12 timeline--header">
            <h4>Past Incidents</h4>
          </div>
          <div class="timeline--item-wrapper">
            <div class="timeline--item">
              <h4>August 24,2021</h4>
              <div class="timeline--content">
                <h5>Scheduled maintenance</h5>
                <div class="progress--status">

                  <p class="progress--status-content">
                    <span>Completed</span> - Helorobo status Site,Corporate Site,Live Chat Socket,Message Template has been added.
                  </p>
                  <p class="progress--status-content">
                    <span>Scheduled</span>  -
                    Added as server properties <br/>
                    <b>-Up Time</b>: how long the server has been running<br/>
                    <b>-Mem Usage</b> : ram usage of the project<br/>
                  </p>
                  <p class="progress--status-time">August 24, 13:14 UTC</p>
                </div>
              </div>

            </div>

            <div class="timeline--item">
              <h4>July 30,2021</h4>
              <div class="timeline--content">
                <h5>Scheduled maintenance</h5>
                <div class="progress--status">

                  <p class="progress--status-content">
                    <span>Completed</span> - Helorobo status Backend,Integration,Worker,Whatsapp,Socket has been added.
                  </p>
                  <p class="progress--status-content">
                    <span>Scheduled</span>  -
                    Added as server properties <br/>
                    <b>-Cpu usages</b>: The amount of cpu used by the project<br/>
                    <b>-Nodejs Version</b> : current used nodejs version<br/>
                    <b>-Tz version</b> : timezone version used in the project<br/>
                  </p>
                  <p class="progress--status-time">July 30, 13:26 UTC</p>
                </div>
              </div>

            </div>
            <div class="timeline--item">
              <h4>July 08,2021</h4>
              <div class="timeline--content">
                <h5>Scheduled maintenance</h5>
                <div class="progress--status">
                  <p class="progress--status-content">
                    <span>Complated</span> - Helorobo status has been published
                  </p>
                  <p class="progress--status-time">July 08, 17:15 UTC</p>
                </div>
              </div>

            </div>





          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="col-12">HeloRobo @2021</div>
    </footer>
  </div>
</template>

<script>
var service = {

  "success": true,
  "data": {
  "backend": {
    "success": true,
        "name": "Backend",
        "data": {
      "up_time": "02:09:29",
          "mem_usage": "73.78 MB",
          "cpu_usage": "5 %",
          "nodejs_version": "14.16.1",
          "tz_version": "2020a"
    }
  },
  "integration": {
    "success": false,
        "name": "Integration",
        "data": {

    }
  },
  "worker": {
    "name": "Worker",
        "success": true,
        "data": {
      "rabbitmq": {
        "success": true,
            "name": "RabbitMq",
            "data": {
          "queues": [
            {
              "name": "worker.fifo.helorobo.queue",
              "waiting_job": 0
            },
            {
              "name": "worker.helorobo.queue",
              "waiting_job": 0
            }
          ],
              "response_time_ms": 0.054
        }
      },
      "success": true,
          "mem_usage": "14.79 MB",
          "up_time": "02:07:28",
          "cpu_usage": "2 %"
    }
  },
  "whatsapp": {
    "success": true,
        "name": "Whatsapp",
        "data": {
      "cpu_usage": "7 %",
          "nodejs_version": "14.16.1",
          "tz_version": "2020a"
    }
  },
  "socket": {
    "success": true,
        "name": "Socket",
        "data": {
      "up_time": "02:10:02",
          "mem_usage": "14.4 MB",
          "cpu_usage": "6 %"
    }
  },
  "site": {
    "success": true,
        "name": "Site",
        "data": {
      "up_time": "01:02:33",
          "mem_usage": "28.92 MB",
          "cpu_usage": "5 %",
          "nodejs_version": "14.16.1",
          "tz_version": "2020a"
    }
  },
  "live_chat_socket": {
    "success": true,
        "name": "Live Chat Socket",
        "data": {
      "up_time": "02:05:49",
          "mem_usage": "11.93 MB",
          "cpu_usage": "6 %"
    }
  },
  "message_template": {
    "success": false,
        "name": "Message Template",
        "data": {

    }
  }
}


}
import { defineComponent,reactive, onMounted ,ref, watch } from 'vue';
import axios from "axios";

export default defineComponent({
  name: "Status",

  setup() {
    const activeKey = ref(['1']);
    let services = reactive([])

    onMounted(()=>{

      axios.post('https://status-backend.helorobo.com/').then((res) =>{
        services.push(res.data.data)
        /*return console.log(res)*/
      }).catch((e)=>{
        console.log('hatalısın dostum')
      })

      window.addEventListener('scroll',function(){
        if(window.scrollY > 400){
          return document.querySelector('.sticky--wrapper').style.opacity = '1'
        }
        document.querySelector('.sticky--wrapper').style.opacity = '0'
      })

      const querySelectorList = [
        '.sticky--wrapper .right'
      ]

      querySelectorList.forEach((querySelector) => {
        const element = document.querySelector(querySelector)

        if (element) {
          element.addEventListener('wheel', (e) => {
            e.preventDefault()
            element.scrollLeft += e.deltaY
          })
        }
      })
    })

    watch(activeKey, val => {

    });

    return {
      activeKey,
      services
    };

  },
})
</script>

<style lang="scss">

</style>